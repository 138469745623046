define([
  'modules/common/components/component',
],
(Component) => {
  const comp = Component.extend({

    epsilon: 0.01,

    checkTax(tax) {
      if (tax < 0 || tax >= 1) {
        throw new Error('Tax rate has to be >=0 and <1');
      }
    },
    /**
             *
             */
    nettoToGross(netto, tax) {
      netto = this.round(netto);
      if (netto == 0) return 0;
      return this.round(netto + this.taxValueFromNetto(netto, tax));
    },
    /**
             *
             */
    grossToNetto(gross, tax) {
      gross = this.round(gross);
      if (gross == 0) return 0;
      return gross - this.taxValueFromGross(gross, tax);
    },
    /**
             *
             */
    taxValueFromNetto(netto, tax) {
      netto = this.round(netto);
      if (netto == 0) return 0;
      this.checkTax(tax);
      return this.round(netto * tax);
    },
    /**
             *
             */
    taxValueFromGross(gross, tax) {
      gross = this.round(gross);
      if (gross <= this.epsilon) return 0;
      this.checkTax(tax);
      return this.round(this.round(gross * tax) / (1 + tax));
    },
    /**
             *
             */
    isCorrect(netto, gross, tax) {
      this.checkTax(tax);
      let epsilon = this.nettoToGross(netto, tax) - gross;
      if (epsilon < 0) {
        epsilon = -epsilon;
      }
      return epsilon < this.epsilon;
    },
    /**
             *
             */
    round(val, precision) {
      if (typeof (precision) !== 'number') {
        precision = 2;
      }
      return Math.round(val * Math.pow(10, precision)) / Math.pow(10, precision);
    },
  });

  return new comp();
});
