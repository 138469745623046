define([
  'jquery',
  'underscore',
  'backbone',
  'simple-keyboard',
  'modules/shop.common/components/mode',
  'modules/common/components/locale',
  'modules/shop.cash-register-retail/models/settings/keyboardLayout',
], (
  $, _, Backbone, SimpleKeyboard, ModeComponent, Locale, KeyboardLayoutSetting,
) => Backbone.Model.extend({

  defaults: {
    // The input target
    $targetEl: null,
    // Keyboard
    keyboard: null,
    $keyboardEl: null,
    // Layout
    layout: null,
    // Cursor position
    cursorPosition: -1,
  },

  /**
         * setting variables
         */
  settings: {
    shrink_app_size: true,
    move_input_into_view: true,
    enabled_by_default: false,
  },

  /**
         * Variables
         */
  ensureTargetExistsInterval: null,
  capsLock: false,

  /**
         * Static variables
         */
  SpecialButtonRegex: /\{(\S*)\}/,
  DocumentEl: $(document),

  /**
   * Constants
   */
  LAYOUT_DEFAULT: 'default',
  LAYOUT_SHIFT: 'shift',
  LAYOUT_NUMBER: 'number',
  LAYOUT_EMAIL: 'email',

  /**
         * Model events
         */
  events: {
    'change:layout': 'layoutChanged',
    'change:$targetEl': 'targetChanged',
    change: 'change',
  },

  change() {
    if (false) {
      console.log(this);
    }
  },

  initialize(settings) {
    this.settings = _.extend({}, this.settings, settings);
    this.removeSettingsFromModel();
    this.startModelEventListeners();
  },

  removeSettingsFromModel() {
    const self = this;
    _.keys(this.settings).forEach((key) => {
      self.unset(key, { silent: true });
    });
  },

  startModelEventListeners() {
    this.on('change:layout', this.layoutChanged, this);
    this.on('change:$targetEl', this.targetChanged, this);
    this.on('change:cursorPosition', this.giveTargetFocus, this);
    this.on('change', this.change, this);
  },

  stopModelEventListeners() {
    this.off('change:layout', this.layoutChanged, this);
    this.off('change:$targetEl', this.targetChanged, this);
    this.off('change:cursorPosition', this.giveTargetFocus, this);
    this.off('change', this.change, this);
  },

  layoutChanged() {
    if (this.has('keyboard') && this.has('$keyboardEl') && this.has('layout')) {
      const layout = this.get('layout');
      this.get('keyboard').setOptions({ layoutName: layout });
      this.get('$keyboardEl').attr('data-layout', layout);
    }
  },

  targetChanged: _.debounce(function () {
    if (this.has('$targetEl')) {
      this.registerTarget();
    } else {
      this.unregisterTarget();
    }
  }),

  spawnKeyboard() {
    const self = this;

    if (!this.isEnabled()) {
      return;
    }

    if (this.has('keyboard')) {
      this.destroyKeyboard(true);
    }

    // Creating the element to create the keyboard against.
    const $body = $(document.body);
    const $keyboard = $('<div>')
      .addClass('storekeeper-keyboard');

    $body.append($keyboard);

    const Keyboard = SimpleKeyboard.default;
    const keyboard = new Keyboard('.storekeeper-keyboard', {
      onKeyPress(keyPressed) { self.onKeyPress(keyPressed); },
      layout: this.getLayouts(),
      display: this.getKeyboardButtons(),
      theme: 'theme-storekeeper-keyboard',
    });

    this.set({
      keyboard,
      $keyboardEl: $keyboard,
      layout: 'default',
    });

    this.registerListeners();
  },

  registerListeners() {
    this.unregisterListeners();

    const self = this;
    this.DocumentEl.on('click', (event) => {
      self.handleDocumentClickEvents(event);
    });
  },

  isEnabled() {
    if (ModeComponent.isInWebMode() || ModeComponent.isInAppMode() || ModeComponent.isInElectronMode()) {
      // we do never show the keyboard in web mode or app mode, e.g. on a tablet
      return false;
    }

    return this.settings.enabled_by_default;
  },

  handleDocumentClickEvents(event) {
    if (!this.isEnabled()) {
      return;
    }

    const $targetEl = $(event.target);
    const ignoredTypes = [
      'submit',
      'reset',
      'radio',
      'checkbox',
      'button',
      'file',
    ];
    if (
      // Check if the target input is an 'input' or an 'textarea'
      ($targetEl.is('input') || $targetEl.is('textarea'))
                // Ignore non text field inputs
                && !(_.contains(ignoredTypes, $targetEl.attr('type')))
                // Check if the target is not readonly.
                && !$targetEl.is('[readonly]')
                // Check if the target is not disabled.
                && !$targetEl.is('[disabled]')
                // Ignores the target when it has the attribute OR the class ignore-keyboard
                && !$targetEl.is('[ignore-keyboard]') && !$targetEl.is('.ignore-keyboard')
    ) {
      this.unset('$targetEl', { silent: true });
      this.set('$targetEl', $targetEl);
    } else if (
      !$targetEl.closest('.hg-button').get(0)
                && !$targetEl.closest('.hg-row').get(0)
                && !$targetEl.closest('.storekeeper-keyboard').get(0)
    ) {
      this.set('$targetEl', null);
    }
  },

  registerTarget() {
    this.fixTargetElement();

    this.setTargetInitialLayout();

    this.startTargetExistsCheck();

    this.updateCursorPosition();

    this.openKeyboard();

    if (this.settings.move_input_into_view) {
      const self = this;
      setTimeout(() => {
        const $el = self.get('$targetEl');
        if ($el && $el.get(0)) {
          $el.get(0).scrollIntoView({ behavior: 'smooth' });
        }
      }, 400);
    }

    this.startCursorListenEvents();
  },

  fixTargetElement() {
    const $targetEl = this.get('$targetEl');
    if ($targetEl && $targetEl.has('[type]')) {
      const type = $targetEl.attr('type');
      // The reason we need to change these fields do not support selectionStart / selectionEnd
      // ref: https://html.spec.whatwg.org/multipage/input.html#the-input-element:dom-textarea/input-selectionstart-3
      if (
        type === 'number'
                    || type === 'email'
      ) {
        $targetEl
          .attr('type', 'text')
          .attr('data-type', type);
      }
    }
  },

  startCursorListenEvents() {
    const $el = this.get('$targetEl');
    if ($el) {
      const self = this;
      $el.on('keydown click', () => {
        self.updateCursorPosition();
      });
    }
  },

  updateCursorPosition() {
    const $el = this.get('$targetEl');
    if ($el && $el.get(0)) {
      // Check if the selectionStart is set set the cursor position. else put it at the end.
      if ($el.get(0).selectionStart <= 0) {
        this.set('cursorPosition', $el.val().length);
      } else {
        this.set('cursorPosition', $el.get(0).selectionStart);
      }
    }
  },

  setTargetInitialLayout() {
    const $el = this.get('$targetEl');
    if ($el) {
      const type = $el.attr('data-type');
      let layout;
      switch (type) {
        case 'number':
          layout = this.LAYOUT_NUMBER;
          break;
        case 'email':
          layout = this.LAYOUT_EMAIL;
          break;
        default:
          layout = this.LAYOUT_DEFAULT;
          break;
      }
      this.set('layout', layout);
    }
  },

  startTargetExistsCheck() {
    const $el = this.get('$targetEl');
    if ($el) {
      const self = this;
      this.stopTargetExistsCheck();
      this.ensureTargetExistsInterval = setInterval(() => {
        if (!$el || $el.closest('body').length <= 0) {
          self.set('$targetEl', null);
        }
      }, 250);
    }
  },

  unregisterTarget() {
    this.stopTargetExistsCheck();

    this.set({
      cursorPosition: -1,
      layout: this.LAYOUT_DEFAULT,
    });

    this.closeKeyboard();
  },

  stopTargetExistsCheck() {
    clearInterval(this.ensureTargetExistsInterval);
  },

  destroyKeyboard(silent) {
    const $keyboardEl = this.get('$keyboardEl');
    let options = {};
    if (silent) {
      options = { silent: true };
    }

    // clearing up the views.
    $keyboardEl.remove();
    this.set({
      keyboard: null,
      $keyboardEl: null,
      layout: null,
    }, options);

    this.unregisterListeners();
  },

  unregisterListeners() {
    const self = this;
    this.DocumentEl.off('click', (event) => {
      self.handleDocumentClickEvents(event);
    });
  },

  getLayouts() {
    const layouts = {};

    if (this.settings.keyboardLayout === KeyboardLayoutSetting.TYPE_QWERTY) {
      layouts[this.LAYOUT_DEFAULT] = [
        '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
        '{empty} q w e r t y u i o p [ ] \\',
        '{capslock} a s d f g h j k l ; \' {enter}',
        '{shiftl} z x c v b n m , . / {shiftr}',
        '{storekeeper} {space} {close}',
      ];

      layouts[this.LAYOUT_EMAIL] = [
        '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
        '{empty} q w e r t y u i o p [ ] \\',
        '{capslock} a s d f g h j k l ; \' {enter}',
        '{shiftl} z x c v b n m , . / {shiftr}',
        '@ {.nl} {space} {close}',
      ];

      layouts[this.LAYOUT_SHIFT] = [
        '~ ! @ # $ % ^ & * ( ) _ + {bksp}',
        '{empty} Q W E R T Y U I O P { } |',
        '{capslock} A S D F G H J K L : " {enter}',
        '{shiftl} Z X C V B N M < > ? {shiftr}',
        '{storekeeper} {space} {close}',
      ];
    } else if (this.settings.keyboardLayout === KeyboardLayoutSetting.TYPE_QWERTZ) {
      // Change any key from the layout here
      layouts[this.LAYOUT_DEFAULT] = [
        '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
        '{empty} q w e r t z u i o p [ ] \\',
        '{capslock} a s d f g h j k l ; \' {enter}',
        '{shiftl} y x c v b n m , . / {shiftr}',
        '{storekeeper} {space} {close}',
      ];

      layouts[this.LAYOUT_EMAIL] = [
        '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
        '{empty} q w e r t z u i o p [ ] \\',
        '{capslock} a s d f g h j k l ; \' {enter}',
        '{shiftl} y x c v b n m , . / {shiftr}',
        '@ {.nl} {space} {close}',
      ];

      layouts[this.LAYOUT_SHIFT] = [
        '~ ! @ # $ % ^ & * ( ) _ + {bksp}',
        '{empty} Q W E R T Z U I O P { } |',
        '{capslock} A S D F G H J K L : " {enter}',
        '{shiftl} Y X C V B N M < > ? {shiftr}',
        '{storekeeper} {space} {close}',
      ];
    }

    layouts[this.LAYOUT_NUMBER] = [
      '1 2 3 {bksp}',
      '4 5 6 {enter}',
      '7 8 9 {storekeeper}',
      ' 0  {close}',
    ];
    return layouts;
  },

  getKeyboardButtons() {
    const defaultButtons = this.getDefaultKeyboardButtons();
    const additionalButtons = {
      '{empty}': '',
      '{.nl}': '.nl',
      '{close}': `${Locale.translate('close')} <i class="fa fa-chevron-down"></i>`,
      '{bksp}': '<span class="text-right">Backspace<br><i class="fa fa-backspace pull-right"></i></span>',
      '{enter}': 'Enter<br><i class="fa fa-level-down fa-rotate-90 pull-right"></i>',
      '{shiftl}': '<i class="fa fa-arrow-up"></i> Shift',
      '{shiftr}': 'Shift <i class="fa fa-arrow-up"></i>',
      '{storekeeper}': ' ',
      '{lock}': 'Caps',
      '{capslock}': 'Caps',
    };

    return _.extend({}, defaultButtons, additionalButtons);
  },

  onKeyPress(key) {
    if (this.SpecialButtonRegex.test(key)) {
      this.handleSpecialButton(key);
    } else {
      this.handleRegularButton(key);
    }
  },

  handleSpecialButton(key) {
    const $targetEl = this.get('$targetEl');
    switch (key) {
      case '{shiftl}':
      case '{shiftr}':
      case '{shift}':
        if ($targetEl.attr('data-type') === 'email') {
          this.toggleKeyboardLayout(this.LAYOUT_EMAIL, this.LAYOUT_SHIFT);
        } else {
          this.toggleKeyboardLayout(this.LAYOUT_DEFAULT, this.LAYOUT_SHIFT);
        }
        this.capsLock = false;
        break;
      case '{capslock}':
        if ($targetEl.attr('data-type') === 'email') {
          this.toggleKeyboardLayout(this.LAYOUT_EMAIL, this.LAYOUT_SHIFT);
        } else {
          this.toggleKeyboardLayout(this.LAYOUT_DEFAULT, this.LAYOUT_SHIFT);
        }
        this.capsLock = this.get('layout') === this.LAYOUT_SHIFT;
        break;
      case '{close}':
        this.set('$targetEl', null);
        break;
      case '{bksp}':
        this.removeCharacterBeforeCursor();
        this.triggerKeydownEventOnDocument('Backspace');
        break;
      case '{enter}':
        $targetEl.is('textarea') ? this.handleRegularButton('\n') : '';
        this.triggerKeydownEventOnDocument('Enter');
        break;
      case '{space}':
        this.handleRegularButton(' ');
        break;
      case '{.nl}':
        this.handleRegularButton('.nl');
        break;
    }
  },

  toggleKeyboardLayout(layoutOne, layoutTwo) {
    const currentLayout = this.get('layout');
    if (currentLayout === layoutTwo) {
      this.set('layout', layoutOne);
      return layoutOne;
    }
    this.set('layout', layoutTwo);
    return layoutTwo;
  },

  handleRegularButton(key) {
    this.addToInputValue(key);

    this.triggerKeydownEventOnDocument(key);

    if (this.get('layout') === this.LAYOUT_SHIFT && !this.capsLock) {
      this.set('layout', this.LAYOUT_DEFAULT);
    }
  },

  giveTargetFocus() {
    const $targetEl = this.get('$targetEl');
    if ($targetEl) {
      const cursorPosition = parseInt(this.get('cursorPosition'));
      $targetEl.focus();
      $targetEl.get(0).selectionStart = cursorPosition;
      $targetEl.get(0).selectionEnd = cursorPosition;
    }
  },

  removeCharacterBeforeCursor() {
    const $el = this.get('$targetEl');
    if ($el) {
      let cursorPosition = parseInt(this.get('cursorPosition'));
      let value = this.getTargetInput();

      if (cursorPosition === -1) {
        cursorPosition = value.length;
      }

      let startString = value.substring(0, cursorPosition);
      const isStart = startString.length !== 0;
      if (isStart) {
        startString = startString.substring(0, startString.length - 1);
      }
      const endString = value.substring(cursorPosition, value.length);
      value = startString + endString;
      if (isStart && cursorPosition !== 0) {
        cursorPosition--;
      }

      this.setTargetInput(value);
      this.set('cursorPosition', cursorPosition);
    }
  },

  addToInputValue(string) {
    if (this.hasTarget()) {
      string = string.toString();
      let cursorPosition = parseInt(this.get('cursorPosition'));
      let value = this.getTargetInput();

      if (cursorPosition === -1) {
        value += string;
      } else {
        const startString = value.substring(0, cursorPosition);
        const endString = value.substring(cursorPosition, value.length);
        value = startString + string + endString;
        cursorPosition += string.length;
      }

      this.setTargetInput(value);
      this.set('cursorPosition', cursorPosition);
    }
  },

  hasTarget() {
    return this.has('$targetEl');
  },

  setTargetInput(value) {
    if (this.hasTarget()) {
      const $targetEl = this.get('$targetEl');
      $targetEl.val(value);
      $targetEl.trigger('keydown');
      $targetEl.trigger('keyup');
      $targetEl.trigger('change');
      if ($targetEl.hasClass('select2-input')) {
        // for some reason when it's select2 field we need to fix the events twice
        // otherwise it will only do the query once every 2 characters
        // maybe it's fixed in newer select 2 (now 3.5.1)
        $targetEl.val(value);
        $targetEl.trigger('keydown');
        $targetEl.trigger('keyup');
        $targetEl.trigger('change');
      }
    }
  },

  getTargetInput() {
    let value = '';
    if (this.hasTarget()) {
      const $targetEl = this.get('$targetEl');
      value = $targetEl.val();
    }
    return value;
  },

  closeKeyboard() {
    const $keyboardEl = this.get('$keyboardEl');
    if ($keyboardEl) {
      $keyboardEl.attr('data-show', false);
    }

    // Let all elements know the keyboard is shown
    $('body').removeClass('keyboard-shown');
  },

  openKeyboard() {
    const $keyboardEl = this.get('$keyboardEl');
    if ($keyboardEl) {
      $keyboardEl.attr('data-show', true);
    }

    // Let all elements know the keyboard is shown
    $('body').addClass('keyboard-shown');
  },

  triggerKeydownEventOnDocument(code, key) {
    key = key || code;
    const eventObj = document.createEventObject
      ? document.createEventObject() : document.createEvent('Events');

    if (eventObj.initEvent) {
      eventObj.initEvent('keydown', true, true);
    }

    eventObj.code = code;
    eventObj.key = key;

    document.dispatchEvent ? document.dispatchEvent(eventObj) : document.fireEvent('onkeydown', eventObj);
  },

  onDestroy() {
    this.stopModelEventListeners();
    this.destroyKeyboard();
  },

  isShown() {
    return this.has('$targetEl');
  },

  getDefaultKeyboardButtons() {
    return {
      '{bksp}': 'backspace',
      '{backspace}': 'backspace',
      '{enter}': '< enter',
      '{shift}': 'shift',
      '{shiftleft}': 'shift',
      '{shiftright}': 'shift',
      '{alt}': 'alt',
      '{s}': 'shift',
      '{tab}': 'tab',
      '{lock}': 'caps',
      '{capslock}': 'caps',
      '{accept}': 'Submit',
      '{space}': ' ',
      '{//}': ' ',
      '{esc}': 'esc',
      '{escape}': 'esc',
      '{f1}': 'f1',
      '{f2}': 'f2',
      '{f3}': 'f3',
      '{f4}': 'f4',
      '{f5}': 'f5',
      '{f6}': 'f6',
      '{f7}': 'f7',
      '{f8}': 'f8',
      '{f9}': 'f9',
      '{f10}': 'f10',
      '{f11}': 'f11',
      '{f12}': 'f12',
      '{numpaddivide}': '/',
      '{numlock}': 'lock',
      '{arrowup}': '\u2191',
      '{arrowleft}': '\u2190',
      '{arrowdown}': '\u2193',
      '{arrowright}': '\u2192',
      '{prtscr}': 'print',
      '{scrolllock}': 'scroll',
      '{pause}': 'pause',
      '{insert}': 'ins',
      '{home}': 'home',
      '{pageup}': 'up',
      '{delete}': 'del',
      '{end}': 'end',
      '{pagedown}': 'down',
      '{numpadmultiply}': '*',
      '{numpadsubtract}': '-',
      '{numpadadd}': '+',
      '{numpadenter}': 'enter',
      '{period}': '.',
      '{numpaddecimal}': '.',
      '{numpad0}': '0',
      '{numpad1}': '1',
      '{numpad2}': '2',
      '{numpad3}': '3',
      '{numpad4}': '4',
      '{numpad5}': '5',
      '{numpad6}': '6',
      '{numpad7}': '7',
      '{numpad8}': '8',
      '{numpad9}': '9',
    };
  },

  hasKeyboard() {
    return this.has('keyboard');
  },

  registerTargetInput($jqueryInputElement) {
    if (this.isEnabled()) {
      this.set('$targetEl', $jqueryInputElement);
    }
  },

  unregisterTargetInput() {
    if (this.isEnabled()) {
      this.set('$targetEl', null);
    }
  },

}));
