define([
  'modules/common/acls/acl',
  './../collections/users',
  './../events/route/unauthorized',
],
(Acl, UsersCollection, UnauthorizedEvent) => Acl.extend({

  authorized() {
    const user = UsersCollection.findWhere({
      active: true,
    });

    return user !== undefined;
  },

  error(route) {
    const event = new UnauthorizedEvent({
      previousRoute: route,
    });
    event.trigger();
  },
}));
