define([
  'jquery',
  'underscore',
  'backbone',
  'upx.modules/CommerceModule/collections/Newsletter',
  'modules/shop.common/models/upx/Newsletter',
], (
  $, _, Backbone, Collection, Model,
) => Collection.extend({

  model: Model,

}));
