define([
  'jquery',
  'upx.modules/ProductsModule/collections/FeaturedAttribute',
],
($, UpxCollection) => {
  const model = UpxCollection.extend({
    offline: true,
    loaded: false,

    ALIAS_BRAND: 'brand',
    ALIAS_BARCODE: 'barcode',
    ALIAS_NEEDS_WEIGHT_ON_KASSA: 'needs_weight_on_kassa',
    ALIAS_NEEDS_DESCRIPTION_ON_KASSA: 'needs_description_on_kassa',
    ALIAS_PRINTABLE_SHORTNAME: 'printable_shortname',
    ALIAS_DURATION_IN_SECONDS: 'duration_in_seconds',
    ALIAS_DURATION_IN_MINUTES: 'duration_in_minutes',

    load() {
      const def = $.Deferred();
      const self = this;
      if (!this.loaded) {
        $.when(this.fetch({
          params: {
            start: 0,
            limit: 0,
          },
        })).then(
          () => {
            self.loaded = true;
            def.resolve();
          },
          () => {
            def.reject('Failed to load the FeaturedAttribute');
          },
        );
      } else {
        def.resolve();
      }

      return def.promise();
    },

    getAttributeByAlias(alias) {
      const attribute = this.findWhere({ alias });
      if (!attribute) {
        return null;
      }
      return attribute;
    },

    getAttributeIdByAlias(alias) {
      const attribute = this.getAttributeByAlias(alias);
      if (!attribute) {
        return null;
      }
      return attribute.get('attribute_id');
    },

    unload() {
      this.loaded = false;
      this.reset();
      this.getCacheStorage().cleanup(); // clean the cache to not load old profile
    },
  });

  const col = new model();
  col.CLASS = model; // needed otherwise not extendable, soooo brutal BUEHHEHEHE
  return col;
});
