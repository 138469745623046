define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/components/locale',
  'shortid',
], (
  $, _, Backbone, Locale, Shortid,
) => ({

  regexLowercase: /[a-z]/,
  regexUppercase: /[A-Z]/,
  regexDigit: /[0-9]/,
  regexSpecial: /[.,:;<>!#@()[{}+=_/$%&?*\]"]/,
  regexUuid: /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/, // RFC4122

  getPasswordValidationRuleFn(password, field, data) {
    const errors = [];
    const regexLowercase = /[a-z]/;
    const regexUppercase = /[A-Z]/;
    const regexDigit = /[0-9]/;
    const regexSpecial = /[.,:;<>!#@()[{}+=_/$%&?*\]\\ "]/;

    if (!password && password.length < 6) {
      errors.push(Locale.translate('password_needs_to_be_at_least_6_characters'));
    }

    if (!regexLowercase.test(password)) {
      errors.push(Locale.translate('password_needs_to_have_at_least_1_lowercase_character'));
    }

    if (!regexUppercase.test(password)) {
      errors.push(Locale.translate('password_needs_to_have_at_least_1_uppercase_character'));
    }

    if (!regexDigit.test(password)) {
      errors.push(Locale.translate('password_needs_to_have_at_least_1_digit'));
    }

    if (!regexSpecial.test(password)) {
      errors.push(Locale.translate('password_needs_to_have_at_least_1_special_character_lessgreater_dollarpercentand'));
    }

    if (errors.length > 0) {
      return errors.join('<br>');
    }
  },

  getPasswordValidationRuleFnAllowEmpty(password) {
    if (password && password !== '') {
      const errors = [];
      const regexLowercase = /[a-z]/;
      const regexUppercase = /[A-Z]/;
      const regexDigit = /[0-9]/;
      const regexSpecial = /[.,:;<>!#@()[{}+=_/$%&?*\]\\ "]/;

      if (password.length < 6) {
        errors.push(Locale.translate('password_needs_to_be_at_least_6_characters'));
      }

      if (!regexLowercase.test(password)) {
        errors.push(Locale.translate('password_needs_to_have_at_least_1_lowercase_character'));
      }

      if (!regexUppercase.test(password)) {
        errors.push(Locale.translate('password_needs_to_have_at_least_1_uppercase_character'));
      }

      if (!regexDigit.test(password)) {
        errors.push(Locale.translate('password_needs_to_have_at_least_1_digit'));
      }

      if (!regexSpecial.test(password)) {
        errors.push(Locale.translate('password_needs_to_have_at_least_1_special_character_lessgreater_dollarpercentand'));
      }

      if (errors.length > 0) {
        return errors.join('<br>');
      }
    }
  },

  generateSimplePassword() {
    const lower = 'abcdefghijklmnopqrstuvwxyz';
    const upper = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numeric = '0123456789';
    const punctuation = '!#$%@&*';
    return this.getRandomString(2, upper)
                + this.getRandomString(7, lower)
                + this.getRandomString(2, numeric)
                + this.getRandomString(1, punctuation);
  },

  getRandomString(length, string) {
    string = string || 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!#$%@&*';
    let text = '';
    for (let i = 0; i < length; i++) text += string.charAt(Math.floor(Math.random() * string.length));
    return text;
  },

  s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  },

  uuid() {
    const { s4 } = this;
    return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
  },

  isUuid(string) {
    return this.regexUuid.test(string);
  },

  shortid() {
    return Shortid.generate();
  },
}));
