define([
  'jquery',
  'underscore',
  'backbone',
  'upx.modules/PaymentModule/collections/ProviderMethodType',

  'modules/common/components/locale',
], (
  $, _, Backbone, UpxCollection,
  Locale,
) => {
  const Collection = UpxCollection.extend({
    offline: true,
    loaded: false,

    load() {
      const def = $.Deferred();
      let { loaded } = this;
      const self = this;

      def.then(() => {
        loaded = true;
      });

      if (!this.loaded) {
        this.fetch({ params: { start: 0, limit: 0 } })
          .then(() => {
            self.each((model) => {
              model.set(
                'name',
                self.getNameFromAlias(model.get('alias')),
              );
            });
            def.resolve();
            self.loaded = true;
          }, def.reject);
      } else {
        def.resolve();
      }
      return def.promise();
    },

    getNameFromAlias(alias) {
      switch (alias) {
        case 'Web':
          return Locale.translate('web');
        case 'InStore':
          return Locale.translate('in_store');
        case 'TerminalDevice':
          return Locale.translate('pin_terminal');
        case 'Other':
          return Locale.translate('other');
        case 'Cash':
          return Locale.translate('cash');
        case 'GiftCard':
          return Locale.translate('gift_card');
        case 'CustomExternalGiftCard':
          return Locale.translate('custom_external_gift_card');
        case 'ExternalGiftCard':
          return Locale.translate('external_gift_card');
        case 'VvvBon':
          return Locale.translate('vvv_bon');
        case 'OnInvoice':
          return Locale.translate('on_invoice');
        case 'OnOrder':
          return Locale.translate('on_order');
      }
      return alias;
    },

    getNameById(id) {
      const model = this.get(id);
      if (model) {
        return model.get('name');
      }
      return '';
    },
  });

  return new Collection();
});
