define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/components/locale',
], (
  $, _, Backbone, Locale,
) => ({
  VERSION_FALLBACK: 'development',

  getVersion() {
    if (!!window.storekeeper && 'version' in window.storekeeper) {
      return window.storekeeper.version;
    }
    return this.VERSION_FALLBACK;
  },

}));
