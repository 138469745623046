define([
  'application',
  'jquery',
  'underscore',
  'backbone',
  'modules/common/components/locale',
], (
  App, $, _, Backbone, Locale,
) => ({

  check() {
    const { uuid } = App.settings;
    const key = `${uuid}~` + 'compatibility_check';
    if (this.isIE11() && !localStorage.getItem(key)) {
      alert(Locale.translate('as_of_26032019_we_discourage_the_usage_of_internet_explorer_11_we_suggest_downloading_google_chrome_for_the_best_experience_dot'));
      localStorage.setItem(key, new Date().toISOString());
    }
  },

  isIE11() {
    // https://stackoverflow.com/a/21825207
    return !!window.MSInputMethodContext && !!document.documentMode;
  },

}));
