define([
  'jquery',
  'underscore',
  'backbone',
  'backbone.deepmodel',
], (
  $, _, Backbone,
) => Backbone.Collection.extend({
  model: Backbone.DeepModel.extend({
    idAttribute: 'alias',
  }),
}));
