define([
  'jquery',
  'underscore',
  'backbone',
  'upx.modules/CommerceModule/models/Newsletter',
], (
  $, _, Backbone, Model,
) => Model.extend({

  getParsedDataHtml() {
    const html = this.get('data_html');
    if (html) {
      let tmpEl = $('<div>');
      tmpEl.html(html);
      // Getting the news body
      tmpEl = tmpEl.find('#templateBody');
      // removing any href tags to prevent the iframe going to a different page.
      tmpEl.find('[href]').removeAttr('href');
      return tmpEl.html();
    }
    return html;
  },

}));
