define([
  'backbone',
  'modules/common/components/moment',
  'modules/common/components/locale',
], (
  Backbone, Moment, Locale,
) => ({

  getDayInWeekArray() {
    const returnArray = [];
    const dayStringArray = [
      Locale.translate('monday'),
      Locale.translate('tuesday'),
      Locale.translate('wednesday'),
      Locale.translate('thursday'),
      Locale.translate('friday'),
      Locale.translate('saturday'),
      Locale.translate('sunday'),
    ];

    dayStringArray.forEach((monthName, index) => {
      returnArray.push({
        index,
        number: index + 1,
        name: monthName,
      });
    });

    return returnArray;
  },

  getDayInWeekCollection() {
    return new Backbone.Collection(
      this.getDayInWeekArray(),
    );
  },

  getDayInMonthArray(month) {
    month = month || Moment().month() + 1;

    const returnArray = [];
    const daysInMonth = Moment().month(month).daysInMonth();

    for (let i = 0; i < daysInMonth; i++) {
      returnArray.push({
        index: i,
        number: i + 1,
        name: i + 1,
      });
    }

    return returnArray;
  },

  getDayInMonthCollection() {
    return new Backbone.Collection(
      this.getDayInMonthArray(),
    );
  },

  getMonthArray() {
    const returnArray = [];
    const monthStringArray = [
      Locale.translate('january'),
      Locale.translate('february'),
      Locale.translate('march'),
      Locale.translate('april'),
      Locale.translate('may'),
      Locale.translate('june'),
      Locale.translate('july'),
      Locale.translate('august'),
      Locale.translate('september'),
      Locale.translate('october'),
      Locale.translate('november'),
      Locale.translate('december'),
    ];

    monthStringArray.forEach((monthName, index) => {
      returnArray.push({
        index,
        number: index + 1,
        name: monthName,
      });
    });

    return returnArray;
  },

  getMonthCollection() {
    return new Backbone.Collection(
      this.getMonthArray(),
    );
  },

  getQuarterArray() {
    const returnArray = [];
    const monthStringArray = [
      Locale.translate('first_quarter'),
      Locale.translate('second_quarter'),
      Locale.translate('third_quarter'),
      Locale.translate('fourth_quarter'),
    ];

    monthStringArray.forEach((monthName, index) => {
      returnArray.push({
        index,
        number: index + 1,
        name: monthName,
      });
    });

    return returnArray;
  },

  getQuarterCollection() {
    return new Backbone.Collection(
      this.getQuarterArray(),
    );
  },

  getYearArray(start) {
    start = parseInt(start) || 2000;
    const returnArray = [];
    const yearArray = [];
    for (let i = Moment().year(); i >= start; i--) {
      yearArray.push(i);
    }

    yearArray.forEach((year, index) => {
      returnArray.push({
        index,
        number: year,
        name: year.toString(),
      });
    });

    return returnArray;
  },

  getYearCollection() {
    return new Backbone.Collection(
      this.getYearArray(),
    );
  },

}));
