define([
  'modules/shop.common/models/onScreenKeyboard',
], (
  OnScreenKeyboard,
) => ({

  onScreenKeyboard: null,

  register(settings) {
    this.onScreenKeyboard = new OnScreenKeyboard(settings);
    this.onScreenKeyboard.spawnKeyboard();
  },

  unRegister() {
    this.onScreenKeyboard.destroy();
  },

  /**
         * If the keyboard is visible
         * @return {boolean}
         */
  isShown() {
    return !!this.onScreenKeyboard.isShown();
  },

  /**
         * If an keyboard was registered
         * @return {boolean}
         */
  isRegistered() {
    return !!(this.onScreenKeyboard && this.onScreenKeyboard.hasKeyboard());
  },

  /**
         * Registers a jQuery input as a target element.
         * @param $targetInputElement An jQuery element
         */
  registerTargetInput($targetInputElement) {
    if (this.onScreenKeyboard) {
      this.onScreenKeyboard.registerTargetInput($targetInputElement);
    }
  },

  /**
         * Unregisters a jQuery input as a target element.
         */
  unregisterTargetInput() {
    if (this.onScreenKeyboard) {
      this.onScreenKeyboard.unregisterTargetInput();
    }
  },

}));
