define([
  'jquery',
  'underscore',
  'backbone',
], (
  $, _, Backbone,
) => ({

  regions: {},

  // Creating a region and put it into the body;
  // This function should mainly be used for views that can't have there own regions
  // But needs to have a region for a modal or popup, Which should not pop in the UI.
  getRegion(regionName) {
    if (regionName in this.regions) {
      return this.regions = this.regions[regionName];
    }

    const $region = $('<div>')
      .data('region', regionName);

    $('body').append($region);
    const region = new Backbone.Marionette.Region({
      el: $region,
    });
    return this.regions[regionName] = region;
  },

  cleanRegion(regionName) {
    if (regionName in this.regions) {
      const region = this.regions[regionName];
      region.reset();
      region.options.el.remove();
      delete this.regions[regionName];
    }
  },

  /**
		 * @param {Function} checkFn Uses this function to check if a region need to be removed. Defaults to true
		 */
  cleanRegions(checkFn = (regionName) => true) {
    _.keys(this.regions).forEach((regionName) => {
      if (checkFn(regionName)) {
        this.cleanRegion(regionName);
      }
    });
  },

  /**
		 * Takes the `regionName` and suffix it with a unique UUID
		 * @param regionName
		 * @return {string}
		 */
  makeRegionNameUnique(regionName) {
    return `${regionName}__${Crypto.uuid()}`;
  },

}));
